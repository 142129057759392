import React from "react"
import { TableRow, Stack } from "@mui/material"
import { PETableCell_V2, PETextField } from "../StyledPackagingExecutionPreviewComponents"
import FormikErrorMessage from "../../../components/FormikErrorMessage" 
import { convertKilosIntoGrams } from "../../../utils/ressources"
import { roundNumber } from "../../../utils"
import {COLORS} from "../../../utils"

const styles = {
	borders: {
		left: { borderLeft: "1px solid #E6E6E6" },
		right: { borderRight: "1px solid #E6E6E6", color: "unset" }
  },
}

const PackagingExecutionSection = ({
	section,
	index,
	editable,
	setFieldTouched,
	onChangeSectionRealWeight,
	_formatCellValue,
	setFocusedFieldIndex
}) => {
	const _renderKiloToGrams = (value, shouldRound = true) => {
		const convertedValue = convertKilosIntoGrams(value)
		if (shouldRound) {
			return roundNumber(convertedValue, 0)
		}
		return convertedValue
	}

	return (
		<TableRow>
			<PETableCell_V2 sx={styles.borders.left}>
				{section.sectionName || "-"}
			</PETableCell_V2>
			<PETableCell_V2>
				{_formatCellValue(
					roundNumber((section.initialProductionWeight || 0), 1),
					roundNumber((section.totalTheoreticalWeight || 0), 1),
					"kg",
					{color:COLORS.DRAFT_GREY}
				)}
			</PETableCell_V2>
			<PETableCell_V2>
				<Stack direction="row" alignItems="center" gap={1}>
					{_formatCellValue(
						_renderKiloToGrams(section.recipeSectionWeight) || 0,
						null,
						"g",
						{color:COLORS.DRAFT_GREY}
					)}
				</Stack>
			</PETableCell_V2>
			<PETableCell_V2>
				{editable ? (
					<Stack direction="row" alignItems="center" gap={1}>
						<PETextField
							name={`sections[${index}].realWeight`}
							value={section.realWeight === "" ? "" : _renderKiloToGrams(section.realWeight, false)}
							variant="outlined"
							onChange={(e) => {
								setFieldTouched(`sections[${index}].realWeight`, true, false)
								onChangeSectionRealWeight(e.target.value)
							}}
							type="number"
							onWheel={(e) => e.target.blur()}
							onKeyDown={(e) => {if (e.key === "Enter") {
								e.preventDefault()
								e.target.blur()
							}}}
              onFocus={() => setFocusedFieldIndex(index)}
              onBlur={() => setFocusedFieldIndex(null)}
						/>
						<span style={{color:COLORS.DRAFT_GREY}}> g</span>
					</Stack>
				) : 
				_formatCellValue(
					_renderKiloToGrams(section.realWeight) || 0,
					null,
					"g",
					{color:COLORS.DRAFT_GREY}
				)}
			<FormikErrorMessage name={`sections[${index}].realWeight`} />
			</PETableCell_V2>
			<PETableCell_V2 
				disabled
				sx={{ ...styles.borders.right, color: COLORS.BLACK800 }}
			>
				{_formatCellValue(
					roundNumber(section.forecastWaste || 0, 1),
					null,
					"kg",
				)}
			</PETableCell_V2>
		</TableRow>
	)
}

export default PackagingExecutionSection