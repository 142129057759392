import { getData } from "../index"

const defaultSate = {
    rules: {},
    history: []
}

export default function (state = defaultSate, action) {
    switch (action.type) {
        case "PLANNING_RULES_LOADED":
            return {
                ...state,
                planningRules: action.planningRules,
            }
        case "PUSH_HISTORY":
            return {
                ...state,
                history: [...state.history, action.history],
            }
        default:
            return {
                ...state
            }
    }
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//

export function getPlanningRules(state, errorIfNotFound = false) {
    return getData(state, "misc.planningRules", errorIfNotFound && "No planning rules found")
}
