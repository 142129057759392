import React, { useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import RepeatIcon from "@mui/icons-material/Repeat"
import { makeStyles } from "@mui/styles"
import { Icon, Typography } from "@mui/material"
import { COLORS } from "../../../utils"
import { getProductionStepsKitchenAreaSelector, getProductionStepsProductionDaySelector, getProductionStepsSiteSelector } from "../../../reducers/Production/ProductionSteps"
import { showProductionStepsLogin } from "../../../actions/ProductionSteps/ProductionStepExecutions"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"

const useStyles = makeStyles({
  headerBlock: {
    width: "100%"
  },
  header: {
    width: "100%"
  },
  headerTitle: {
    display: "flex",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
    color: COLORS.PRODUCTION_RECEPTION_COLOR
  },
  headerSubtitle: {
    paddingLeft: 8,
    fontSize: 16,
    color: COLORS.DRAFT_GREY
  }
})

const UnpackingPSEsHeader = ({ pathname = "", rightAction, onBack}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const productionDay = useSelector(getProductionStepsProductionDaySelector)
  const site = useSelector(getProductionStepsSiteSelector)
  const kitchenArea = useSelector(getProductionStepsKitchenAreaSelector)

  const subtitle = useMemo(() => {
    const formattedProductionDay = moment.utc(productionDay).format("DD/MM/YYYY")
    return `/ ${site?.name} - ${kitchenArea?.name} - ${formattedProductionDay}`
  }, [pathname, site, kitchenArea, productionDay])

  const handleReturn = () => dispatch(showProductionStepsLogin())

  return (
    <div className={classes.headerBlock}>
      <GenericTopBarHeader
        leftAction={<GenericHeaderReturnButton handleClick={onBack || handleReturn} />}
        title={(
          <div className={classes.headerTitle}>
            <Icon>
              <RepeatIcon />
            </Icon>
            <Typography variant="h7" sx={{ fontWeight: 500 }}>
              Déconditionnement
            </Typography>
          </div>
        )}
        info={
          <p className={classes.headerSubtitle}>
            {subtitle}
          </p>
        }
        rightAction={rightAction}
        className={classes.header}
      />
    </div>
  )
}

export default UnpackingPSEsHeader