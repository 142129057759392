import React, { useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn"

import { showSupplierItem } from "../../../actions/Supplier/supplierItems"
import { extractSupplierItemsBaseCsv } from "../../../actions/Supplier/supplierItems"
import { roundNumber } from "../../../utils"
import { getSupplierItemTypeLabel, getSupplierItemUnitPrice, getUnitValue, supplierItemTypesArray } from "../../../utils/supplierItemUtils"

import DataGrid from "../../../components/DataGrid/DataGrid"
import { SUPPLIER_ITEM_DATAGRID_STORAGE } from "../../../utils/constant"

export const SupplierItemList = () => {
    const supplierItems = useSelector(state => state.supplierItems.supplierItems)
    const suppliers = useSelector(state => state.supplierItems.suppliers)
    const [data, setData] = useState([])

    const columns = [
        {
            key: "id",
            label: "ID",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                strict: true
            },
            width: 120
        },
        {
            key: "name",
            label: "Nom",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            }
        },
        {
            key: "reference",
            label: "Réference",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text"
            },
            width: 100
        },
        {
            key: "type",
            label: "Type",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                options: supplierItemTypesArray.map((supplierItemType) => ({
                    key: supplierItemType.value,
                    value: supplierItemType.label,
                    label: supplierItemType.label
                }))
            },
            width: 100
        },
        {
            key: "supplier",
            label: "Fournisseur",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                withSearch: true,
                options: suppliers.map((supplier) => ({
                    key: supplier.id,
                    value: supplier.get("name"),
                    label: supplier.get("name")
                }))
            },
            width: 100
        },
        {
            key: "orderUnit",
            label: "Unité de commande",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
                type: "text"
            },
            width: 150
        },
        {
            key: "pricePerOrderUnit",
            label: "Prix par unité de commande (€)",
            dataType: "number",
            sortable: false,
            filter: {
                filterable: false,
                type: "text"
            },
            width: 130
        },
        {
            key: "pricePerKg",
            label: "Prix au kg (€)",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
                type: "text"
            },
            width: 100
        },
        {
            key: "pricePerUnit",
            label: "Prix d'une pièce (€)",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
                type: "text"
            },
            width: 100
        },
        {
            key: "state",
            label: "Etat",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                options: [
                    { key: "active", value: "active", label: "Actif" },
                    { key: "inactive", value: "inactive", label: "Inactif" },
                ]
            },
            render: (state) => {
                if (state === "active") {
                    return <CheckCircleIcon sx={{ color: "#4bb200" }} />
                } else {
                    return <DoDisturbOnIcon sx={{ color: "#c4c2c6" }} />
                }
            },
            width: 100
        },
        {
            key: "updatedAt",
            label: "Date de modification",
            dataType: "date",
            type: "date",
            sortable: true,
            filter: {
                filterable: false,
                type: "date"
            },
            width: 130
        }
    ]

    const dispatch = useDispatch()

    const createData = (supplierItem) => {
        const serializedSupplierItem = {
            id: supplierItem.id,
            name: supplierItem.get("name"),
            reference: supplierItem.get("supplierArticleId"),
            type: getSupplierItemTypeLabel(supplierItem.get("type")),
            supplier: supplierItem?.get("supplier")?.get("name") || "",
            orderUnit: getUnitValue(supplierItem.get("units"), "order", "unity"),
            pricePerOrderUnit: getUnitValue(supplierItem.get("units"), "order", "price"),
            pricePerKg: roundNumber(supplierItem.get("pricePerKg"), 3) || "",
            pricePerUnit: getSupplierItemUnitPrice(supplierItem.get("units")) || "",
            state: supplierItem.get("isActive") ? "active" : "inactive",
            updatedAt: supplierItem.updatedAt.toLocaleDateString("fr-FR", { year: "numeric", month: "numeric", day: "numeric" }),
        }

        return serializedSupplierItem
    }

    const createDataObject = useCallback(() => {
        setData(supplierItems.map(createData))
    }, [supplierItems])

    useEffect(() => {
        createDataObject()
    }, [supplierItems])

    const _showSupplierItem = (supplierItem) => {
        dispatch(showSupplierItem(supplierItem.id))
    }

    const onExtractSupplierItemsBaseCsv = () => {
        dispatch(extractSupplierItemsBaseCsv())
    }

    const moreMenus = [
        {
            onClick: onExtractSupplierItemsBaseCsv,
            label: "Extraire la base article",
        }
    ]

    return (
        <DataGrid
            title="Articles fournisseurs"
            columns={columns}
            data={data}
            menus={moreMenus}
            withFilters
            rowLabel="produits internes"
            onRowClick={_showSupplierItem}
            localStorageKey={SUPPLIER_ITEM_DATAGRID_STORAGE}
        />
    )
}

export default SupplierItemList
