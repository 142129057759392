import React, { useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import { Stack, TextField } from "@mui/material"
import { handlePSEWeighingMergedError } from "../../../utils/productionStepExecution"
import { COLORS, roundNumber } from "../../../utils"

const useStyles = makeStyles({
  root: {
    border: "1px solid #E6E6E6",
    padding: "24px",
    gap: "24px",
    borderRadius: "8px"
  },
  label: {
    color: "#262626",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "22px"
  },
  titleContainer: {
    display: "flex",
    flex: "1 1",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8
  },
  title: {
    color: "#555",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px"
  },
  titleP24: {
    composes: "$title",
    paddingLeft: 24
  },
  instructions: {
    color: "#262626",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px"
  },
  singleValueContainer: {
    display: "flex",
  },
  singleValueContainerP24: {
    display: "flex",
    paddingLeft: 24
  },
  blocMachine: {
    display: "flex",
    flexDirection: "row",
    flex: 1
  },
  fieldValue: {
    fontFamily: "Roboto",
    color: "#262626",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    flexDirection: "row",
    composes: "flexRow"

  },
  fieldValueMachinesNames: {
    composes: "$fieldValue"
  },
  inputWrapper: {
    textAlign: "center",
    width: 100
  },
  inputWeight: {
    padding: "26px 0 26px 26px"
  },
  subBlocMachine1: {
    flex: 0.9
  },
  subBlocMachine: {
    flex: 0.1
  },
  error: {
    color: "#F44259"
  },
  sectionField: {
    color: COLORS.DRAFT_GREY,
    margin: 0,
    fontWeight: 400
  },
})

const PSEMergedWeighing = ({ productionStepExecution, hasWeightMergedFormErrors, setFieldValue, values }) => {

  const classes = useStyles()
  const mergedProductionStepExecutions = productionStepExecution.mergedProductionStepExecutions || []
  const [theoreticalNetWeight, setTheoreticalNetWeight] = useState([])
  const totalTheoreticalNetWeight = values && values.netWeight
  const [pseTheoreticalNetWeightFilled, setPSETheoreticalNetWeightFilled] = useState(0)
  const [netWeightMergedError, setNetWeightMergedError] = useState(null)
  const sectionName = productionStepExecution.mergedProductionStepExecutions && productionStepExecution.mergedProductionStepExecutions[0].sectionName

  useEffect(() => {
    const netWeightMergedError = handlePSEWeighingMergedError(theoreticalNetWeight, pseTheoreticalNetWeightFilled, totalTheoreticalNetWeight)
    setNetWeightMergedError(netWeightMergedError)
  }, [pseTheoreticalNetWeightFilled, theoreticalNetWeight, hasWeightMergedFormErrors])


  return (
    <Stack>
      <Stack className={classes.root}>
        <Stack direction="row" spacing={1} alignItems="center">
          <img src="/img/recipes/balance.svg" />
          <span className={classes.label}>Répartition par recette</span>
        </Stack>
        {mergedProductionStepExecutions.map(pse => {
          return (
            <div className={classes.blocMachine} key={pse.objectId}>
              <div className={classes.subBlocMachine1}>
                <div className={classes.titleContainer}>
                  <div className={classes.title}>
                    Recette
                  </div>
                </div>
                <div className={classes.singleValueContainer}>
                  <span className={classes.fieldValue}>
                    <b>{pse.uniqueCode}&nbsp;</b>-&nbsp;{pse.recipeName}
                    <p className={classes.sectionField}>&nbsp;({sectionName})</p>
                  </span>
                </div>
              </div>
              <div className={classes.subBlocMachine}>
                <div className={classes.titleContainer}>
                  <div className={classes.titleP24}>
                    Poids Prévu
                  </div>
                </div>
                <div className={classes.singleValueContainerP24}>
                  <span className={classes.fieldValue}>{roundNumber(pse.theoreticalNetWeight, 2)} kg</span>
                </div>
              </div>
              <div className={classes.inputWeight}>
                <TextField
                  variant="standard"
                  name="netWeightMerged"
                  inputProps={{
                    className: classes.inputWrapper,
                    type: "text",
                  }}
                  className={classes.inputWrapper}
                  value={null}
                  onChange={(e) => {
                    let value = e.target.value
                    if (typeof value === "string") value = value.replace(",", ".")
                    value = value ? parseFloat(value) : null
                    const idPSE = pse.productionStepExecution.objectId

                    // Copy the array to avoid mutating state directly
                    const updatedTheoreticalNetWeight = [...theoreticalNetWeight]
                    // Find index of existing entry
                    const index = updatedTheoreticalNetWeight.findIndex(obj => obj.idPSE === idPSE)

                    if (value === null) {
                      if (index !== -1) updatedTheoreticalNetWeight.splice(index, 1)
                    } else {
                      if (index !== -1) updatedTheoreticalNetWeight[index] = { idPSE, value }
                      else updatedTheoreticalNetWeight.push({ idPSE, value })
                    }
                  
                    setTheoreticalNetWeight(updatedTheoreticalNetWeight)
                    setFieldValue("netWeightMerged", updatedTheoreticalNetWeight)
                  
                    const pseTheoreticalNetWeightFilled = updatedTheoreticalNetWeight
                      .map(obj => obj.value || 0)
                      .reduce((sum, current) => sum + current, 0)
                  
                    setPSETheoreticalNetWeightFilled(pseTheoreticalNetWeightFilled)
                    const netWeightMergedError = handlePSEWeighingMergedError(
                      updatedTheoreticalNetWeight,
                      pseTheoreticalNetWeightFilled,
                      totalTheoreticalNetWeight
                    )
                    setFieldValue("hasError", !!netWeightMergedError)
                  }}
                  type="number"
                  placeholder={"Poids attribué"}
                  onWheel={(e) => e.target?.blur()}
                  error={theoreticalNetWeight && theoreticalNetWeight.length === 0}
                />
              </div>
            </div>
          )
        })}
        <Stack spacing={3} sx={{ fontSize: 12, fontWeight: 400, flexDirection: "row", composes: "flexRow", justifyContent: "flex-end", alignSelf: "flex-end", alignItems: "flex-end" }}>
          Poids total attribué :
          <span className={pseTheoreticalNetWeightFilled !== 0 && pseTheoreticalNetWeightFilled !== totalTheoreticalNetWeight ? classes.error : null}>&nbsp;{roundNumber(pseTheoreticalNetWeightFilled, 2)}&nbsp;</span>/ {roundNumber(totalTheoreticalNetWeight, 2)} kg
        </Stack>
      </Stack>
      {netWeightMergedError &&
        (
          <Stack sx={{ color: "#F44259", pt: 2 }}>
            {netWeightMergedError}
          </Stack>
        )
      }
    </Stack>
  )
}

export default PSEMergedWeighing
