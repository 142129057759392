import React, { useLayoutEffect } from "react"
import { Router, Route } from "react-router"
import App from "./containers/App"
import NotFound from "./components/NotFound"
import Login from "./containers/login/Login"
import CreateAccount from "./containers/login/CreateAccount"
import RequestPasswordChange from "./containers/login/RequestPasswordChange"
import ChangeAccountPassword from "./containers/login/ChangeAccountPassword"
import RecipeNew from "./containers/RecipeNew/RecipeNew"
import {
    onEnterRecipes,
    onEnterSingleRecipe
} from "./actions/Recipe/recipes"
import {
    onEnterPackaging,
    onEnterSinglePackaging
} from "./actions/Packaging/packaging"
import { displaySidebar, onEnterApp, updateMenu } from "./actions/Utils/app"
import ProductionList from "./containers/Production/ProductionList"
import ProductionCreation from "./containers/Production/ProductionCreation"
import ProductionDetail from "./containers/Production/ProductionDetail"
import {
    onEnterProductions,
    onEnterSingleProduction
} from "./actions/Recipe/productions"
import {
    onEnterSingleSubcontractorProduct,
    onEnterSubcontractorsProducts
} from "./actions/Subcontractor/subcontractorsProducts"
import SubcontractorProductNew from "./containers/Subcontractors/SubcontractorProductNew/SubcontractorProductNew"
import {
    onEnterSubcontractorsCommercialNames,
    onEnterSingleSubcontractorCommercialName
} from "./actions/Ingredients/External/SubcontractorsCommercialNames"
import SubcontractorCommercialNameForm from "./containers/Ingredients/External/SubcontractorCommercialNameForm"
import SubcontractorCommercialName from "./containers/Ingredients/External/SubcontractorCommercialName"
import FamilyIngredientForm from "./containers/Settings/Ingredients/Classifications/Families/FamilyIngredientForm"
import FamilyIngredientRead from "./containers/Settings/Ingredients/Classifications/Families/FamilyIngredientRead"
import GroupIngredientForm from "./containers/Settings/Ingredients/Classifications/Groups/GroupIngredientForm"
import GroupIngredientRead from "./containers/Settings/Ingredients/Classifications/Groups/GroupIngredientRead"
import AllergenIngredientForm from "./containers/Settings/Ingredients/Classifications/Allergens/AllergenIngredientForm"
import AllergenIngredientRead from "./containers/Settings/Ingredients/Classifications/Allergens/AllergenIngredientRead"
import {
    onEnterCommercialNames,
    onEnterSingleCommercialName
} from "./actions/Ingredients/Internal/CommercialsNames"
import CommercialNameForm from "./containers/Ingredients/Internal/CommercialNameForm"
import CommercialName from "./containers/Ingredients/Internal/CommercialName"
import SectionGenericForm from "./containers/Sections/SectionGenericForm"
import {
    onEnterSectionsGeneric,
    onEnterSingleSectionGeneric
} from "./actions/Section/SectionsGeneric"
import {
    onEnterDispatch,
    onEnterModalDispatch,
    onEnterDispatchDetail,
} from "./actions/Dispatch/dispatch"
import {
    onEnterReceptionWarehouse,
    onEnterSingleReceptionWarehouseDetail,
    onEnterMultipleReceptionWarehouseDetail,
    onEnterReceptionWarehouseFilter
} from "./actions/ReceptionWarehouse/receptionWarehouse"
import Dispatch from "./containers/Dispatch/Dispatch"
import ProductDispatch from "./containers/Dispatch/Product/ProductDispatch"
import HubDispatch from "./containers/Dispatch/Hub/HubDispatch"
import ReceptionWarehouse from "./containers/ReceptionWarehouse/ReceptionWarehouse"
import DispatchDetail from "./containers/Dispatch/Product/DispatchDetail"
import HubDispatchDetail from "./containers/Dispatch/Hub/HubDispatchDetail"
import SingleReceptionWarehouseDetail from "./containers/ReceptionWarehouse/SingleReceptionWarehouseDetail"
import MultiReceptionWarehouseDetail from "./containers/ReceptionWarehouse/MultiReceptionWarehouseDetail"
import {
    onEnterPlanningSell,
    onEnterPlanningProduction,
    onEnterPlanningPackaging,
    onEnterPlanningSetting,
    onEnterPlanningPrev,
    onEnterMealPlanner,
    onEnterWeeklyMealPlanner
} from "./actions/Planning/Planning"
import {
    onEnterPlanningDashboard,
    onEnterPlanningDashboardMonitoring,
    onEnterPlanningDashboardKo
} from "./actions/Planning/PlanningDashboard"
import PlanningSell from "./containers/Planning/PlanningSell"
import PlanningPrev from "./containers/Planning/PlanningPrev"
import RecipesTab from "./containers/Products/RecipesTab"
import PlanningsProductionTab from "./containers/Plannings/PlanningsProductionTab"
import PlanningsPackagingTab from "./containers/Plannings/PlanningsPackagingTab"
import SubcontractorProductsTab from "./containers/Products/SubcontractorProductsTab"
import TechnicalSheetTabs from "./containers/Settings/TechnicalSheet/TechnicalSheetTabs"
import MealPlanner from "./containers/MealPlanner/MealPlanner"
import WeeklyMealPlannerPage from "./containers/MealPlanner/WeeklyMealPlannerPage"
import {
    onEnterProducts,
    onEnterProductsResources
} from "./actions/Products/Products"
import SectionsGenericsTab from "./containers/Settings/TechnicalSheet/Resources/SectionsGenericsTab"
import PackagingTab from "./containers/Settings/TechnicalSheet/Resources/PackagingTab"
import { onEnterProductsTags } from "./actions/ProductsTags/ProductsTags"
import InternalCommercialNamesTab from "./containers/Ingredients/InternalCommercialNamesTab"
import SubcontractorIngredientsTab from "./containers/Ingredients/SubcontractorIngredientsTab"
import ClassificationsTab from "./containers/Settings/Ingredients/IngredientsTabs"
import GroupsIngredientsTab from "./containers/Settings/Ingredients/Classifications/GroupsIngredientsTab"
import FamiliesIngredientsTab from "./containers/Settings/Ingredients/Classifications/FamiliesIngredientsTab"
import AllergensIngredientsTab from "./containers/Settings/Ingredients/Classifications/AllergensIngredientsTab"
import {
    onEnterFamiliesClassificationsIngredients
} from "./actions/Ingredients/Classifications/Families/Families"
import {
    onEnterGroupsClassificationsIngredients,
    onEnterSingleGroupClassificationIngredients,
} from "./actions/Ingredients/Classifications/Groups/Groups"
import {
    onEnterAllergensClassificationsIngredients,
} from "./actions/Ingredients/Classifications/Allergens/Allergens"
import {
    onEnterClassificationsIngredients
} from "./actions/Ingredients/Classifications/Classifications"
import ProductsTagsResourcesTab from "./containers/Settings/TechnicalSheet/Resources/ProductsTagsResourcesTab"
import ProductTagRead from "./containers/Settings/TechnicalSheet/Resources/ProductsTags/ProductTagRead"
import ProductTagForm from "./containers/Settings/TechnicalSheet/Resources/ProductsTags/ProductTagForm"

import ReportsTab from "./containers/Settings/Production/Reports/ReportsTab"
import PlanningPage from "./containers/Planning/PlanningPage"
import {
    onEnterPlanningSettingReport
} from "./actions/Planning/Settings/PlanningReport"
import DistributionCenterViewPage from "./containers/Settings/SitesAndHubs/DistributionCenters/DistributionCenterViewPage"
import DistributionCentersTab from "./containers/Settings/SitesAndHubs/DistributionCenters/DistributionCentersTab"
import {
    onEnterPlanningSettingSingleDistributionCenter,
    onEnterPlanningSettingsDistributionCentersList
} from "./actions/Planning/Settings/PlanningSettingsDistributionCenters"
import SettingsDispatchPriorityTab from "./containers/Settings/SitesAndHubs/DispatchPriority/SettingsDispatchPriorityTab"
import {
    onEnterPlanningSettingsDispatchPriority
} from "./actions/Planning/Settings/PlanningSettingsDispatchPriority"
import Rights from "./containers/Rights/RightsPage"
import RightsUsersTab from "./containers/Rights/RightsUsersTab"
import RightsRolesTab from "./containers/Rights/RightsRolesTab"
import {
    onEnterRightsRoles,
    onEnterRightsUsers
} from "./actions/Rights/Rights"
import {
    onEnterChefs
} from "./actions/Chefs/Chefs"
import {
    onEnterSites,
    onEnterSingleSite
} from "./actions/Site/sites"
import {
    onEnterSuppliers,
    onEnterSingleSupplier,
    onLeaveSingleSupplier
} from "./actions/Supplier/suppliers"
import ChefsTabs from "./containers/Settings/TechnicalSheet/Resources/ChefsTabs"
import ChefRead from "./containers/Settings/TechnicalSheet/Resources/Chefs/ChefRead"
import ChefForm from "./containers/Settings/TechnicalSheet/Resources/Chefs/ChefForm"
import Reception from "./containers/Reception/Reception"
import ReceptionFilter from "./containers/Reception/ReceptionFilter"
import Inventory from "./containers/Inventory/Inventory"
import InventoryFilter from "./containers/Inventory/InventoryFilter"
import {
    onEnterReceptionFilter,
    onEnterInventoryFilter
} from "./actions/Reception/reception"
import LotGroup from "./containers/LotGroup/LotGroup"
import LotGroupDetail from "./containers/LotGroup/LotGroupDetail"
import {
    onEnterLotGroup,
    onEnterLotGroupDetail,
    onEnterLotGroupFilter
} from "./actions/LotGroup/lotGroup"
import { onEnterReceptionGap } from "./actions/Reception/receptionGap"
import AdvancedSearchContainer from "./containers/Products/AdvancedSearchContainer"
import { onEnterAdvancedSearch } from "./actions/Products/SearchAdvanced"
import Supplier from "./containers/Suppliers/Suppliers/Supplier"
import {
    onEnterSingleSupplierItem,
    onEnterSupplierItems
} from "./actions/Supplier/supplierItems"
import SuppliersTab from "./containers/Suppliers/SuppliersTab"
import SupplierItemsTab from "./containers/Suppliers/SupplierItemsTab"
import SupplierItem from "./containers/Suppliers/SupplierItems/SupplierItem"
import {
    onEnterOrderReception,
    onEnterSingleOrderReception,
    onEnterOrderReceptionList,
    onEnterOrderSupplierItemLots,
    onEnterOrderReceptionQuantities,
    onEnterOrderDeliveryNote,
} from "./actions/OrderReception/OrderReception"
import OrderReception from "./containers/Order/Reception/OrderReception"
import OrderReceptionTabs from "./containers/Order/Reception/OrderReceptionTabs"
import OrderDeliveryNote from "./containers/Order/DeliveryNote/OrderDeliveryNote"
import DeliveryNoteMainControl from "./containers/Order/DeliveryNote/MainControl"
import { SingleOrderReception } from "./containers/Order/Reception/SingleOrderReception"
import LotInventory from "./containers/LotInventory/LotInventory"
import LotOutput from "./containers/LotInventory/LotOutput"
import LotMain from "./containers/LotInventory/LotMain"
import LotDamageReport from "./containers/LotInventory/LotDamageReport"
import LotEventsHistory from "./containers/LotInventory/LotEventsHistory"
import {
    onEnterLotDamageReport,
    onEnterLotEventsHistory,
    onEnterLotFilter,
    onEnterLotMain,
    onEnterLotsEvents
} from "./actions/LotInventory/LotInventory"
import SupplierItemLots from "./containers/Order/Lot/SupplierItemLots"
import OrderReceptionQuantities from "./containers/Order/Reception/OrderReceptionQuantities"
import Orders from "./containers/Order/Orders/Orders"
import {
    onEnterNewOrder,
    onEnterOrders,
    onEnterOrdersList,
    onEnterOrderUpdate
} from "./actions/Orders/Orders"
import Order from "./containers/Order/Orders/Order"
import OrdersTabs from "./containers/Order/Orders/OrdersTabs"
import BillingCreditNotes from "./containers/Order/BillingAndCreditNotes/BillingCreditNotes"
import BillingCreditNotesTabs from "./containers/Order/BillingAndCreditNotes/BillingCreditNotesTabs"
import BillingCreditNotesControl from "./containers/Order/BillingAndCreditNotes/BillingCreditNotesControl"
import { onEnterBillingAndCreditNotes, onEnterBillingAndCreditNotesList } from "./actions/BillingCreditNotes/BillingCreditNotes"
import Monitoring from "./containers/Monitoring/Monitoring"
import SettingsTabs from "./containers/Settings/SettingsTabs"
import ReceptionGap from "./containers/Supervisions/ReceptionGapTab/ReceptionGap"
import KitchenOutputTab from "./containers/Supervisions/KitchenOutputTab/KitchenOutputTab"
import ProductionTabs from "./containers/Settings/Production/ProductionTabs"
import SitesTab from "./containers/Settings/SitesAndHubs/Sites/SitesTab"
import SitesAndHubsTabs from "./containers/Settings/SitesAndHubs/SitesAndHubsTabs"
import Site from "./containers/Settings/SitesAndHubs/Sites/Site"
import MachinesTab from "./containers/Settings/Production/Machines/MachinesTab"
import { onEnterMachineTypes, onEnterSingleMachineType } from "./actions/Machines/Machines"
import MachineModels from "./containers/Settings/Production/Machines/Model/MachineModels"
import { KitchenAreaMachines } from "./containers/Settings/SitesAndHubs/Sites/KitchenAreaMachines/KitchenAreaMachines"
import TransformationModesTab from "./containers/Settings/Production/TransformationModes/TransformationModesTab"
import { onEnterKitchenArea } from "./actions/Site/machines"
import { onEnterTransformationModes } from "./actions/TransformationModes/TransformationModes"
import LotOutputEvents from "./containers/LotInventory/LotOutputEvents"
import Misc from "./containers/Misc/Misc"
import ReceptionWarehouseFilter from "./containers/ReceptionWarehouse/ReceptionWarehouseFilter"
import LotGroupFilter from "./containers/LotGroup/LotGroupFilter"
import ReusableStepsTab from "./containers/Settings/TechnicalSheet/Resources/ReusableStepsTab"
import {
    onEnterReusableProductionStepEdition,
    onEnterReusableProductionStepCreation,
    onEnterReusableProductionSteps,
    onEnterReusableProductionStepView
} from "./actions/Steps/reusableProductionSteps."
import ReusableStepForm from "./containers/Settings/TechnicalSheet/Resources/reusableSteps/ReusableStepForm"
import { onEnterProductTypes, onEnterSettingsAdministrationtTab } from "./actions/ProductTypes/productTypes"
import AdministrationTabs from "./containers/Settings/Administration/AdministrationTabs"
import ProductTypesTab from "./containers/Settings/Administration/ProductTypes/ProductTypesTab"
import PackagingMaterialsTab from "./containers/Settings/Administration/PackagingMaterials/PackagingMaterialsTab"

import EditProductTypes from "./containers/Settings/Administration/ProductTypes/EditProductTypes"
import PackagingForm from "./containers/Packaging/PackagingForm"
import ProductionSchemasTab from "./containers/Settings/Production/schemas/ProductionSchemasTab"
import ProductionSchemasForm from "./containers/Settings/Production/schemas/ProductionSchemasForm"
import { onEnterProductionSchemas } from "./actions/ProductionSchemas/ProductionSchemas"
import ProductionStepsFilter from "./containers/ProductionStepExecutions/ProductionStepsFilter"
import ProductionStepsRead from "./containers/ProductionStepExecutions/ProductionStepsRead"
import ProductionStepsSupervision from "./containers/ProductionStepExecutions/ProductionStepsSupervision"
import ProductionStepsHoursEdit from "./containers/ProductionStepExecutions/ProductionStepsHoursEdit"
import ProductionStepExecution from "./containers/ProductionStepExecutions/ProductionStepExecution"

import {
    onEnterProductionStepsSupervision,
    onEnterProductionStepsRead,
    onEnterProductionStepsHoursEdit,
    onEnterProductionStepExecution,
    onEnterUnpackingPSEsBySupplierItems
} from "./actions/ProductionSteps/ProductionStepExecutions"
import PackagingExecutionFilter from "./containers/PackagingExecution/PackagingExecutionFilter"
import { onEnterPackagingExecutionFilter, onEnterPackagingExecutionRead, onEnterPackagingExecutionHoursEdit, onEnterPackagingExecution } from "./actions/PackagingExecution/packagingExecution"
import PackagingExecutionRead from "./containers/PackagingExecution/PackagingExecutionRead"
import PackagingExecutionHoursEdit from "./containers/PackagingExecution/PackagingExecutionHoursEdit"
import PackagingExecution from "./containers/PackagingExecution/PackagingExecution"
import Packaging from "./containers/Packaging/Packaging"
import { onEnterPackagingMaterials } from "./actions/packagingMaterials/packagingMaterials"
import { useLocation } from "react-use"
import { FORCE_DISPLAY_SIDEBAR_URLS, HIDE_SIDEBAR_DYNAMIC_URLS, HIDE_SIDEBAR_STANDARD_URLS } from "./utils"
import SecondaryDLCFilter from "./containers/SecondaryDLC/SecondaryDLCFilter"
import SecondaryDLC from "./containers/SecondaryDLC/SecondaryDLC"
import { onEnterSecondaryDLCFilter, onEnterSecondaryDLC } from "./actions/SecondaryDLC/secondaryDLC"
import ProductionReceptionFilter from "./containers/ProductionReception/ProductionReceptionFilter"
import {
    onEnterProductionReceptionExecution,
    onEnterProductionReceptionFilterExecution, onEnterProductionReceptionToReceiveExecution
} from "./actions/ProductionReception/ProductionReception"
import ProductionReception from "./containers/ProductionReception/ProductionReception"
import ProductionReceptionToReceive from "./containers/ProductionReception/ProductionReceptionToReceive"
import ProductionSubstitutions from "./containers/ProductionSubstitutions/ProductionSubstitutions"
import { onEnterProductionSubstitutions } from "./actions/ProductionSubstitutions/ProductionSubstitutions"
import { onEnterMisc } from "./actions/Misc/Misc"
import UnpackingPSEs from "./containers/ProductionStepExecutions/unpacking/UnpackingPSEs"
import { SUPPLIER_ITEM_DATAGRID_STORAGE } from "./utils/constant"

const Routes = ({ store, history }) => {
    const location = useLocation()
    const pathName = location.pathname

    if (FORCE_DISPLAY_SIDEBAR_URLS.includes(pathName)) {
        store.dispatch(displaySidebar(true))
    } else {
        if (HIDE_SIDEBAR_STANDARD_URLS.includes(pathName)) {
            store.dispatch(displaySidebar(false))
        } else {
            let isDisplayed = true
            HIDE_SIDEBAR_DYNAMIC_URLS.forEach(dynamicUrl => {
                if (isDisplayed && pathName.startsWith(dynamicUrl.path) && (pathName.length > dynamicUrl.minLength)) {
                    isDisplayed = false
                }
            })

            store.dispatch(displaySidebar(isDisplayed))
        }
    }

    useLayoutEffect(() => {
        store.dispatch(updateMenu(false))
        if (location.pathname) {
            const history = store.getState().misc.history
            const previousRoute = history.length > 0 ? history[history.length - 1] : ""
            if (previousRoute.includes("/suppliers") && !location.pathname.includes("/suppliers")) {
                localStorage.setItem(SUPPLIER_ITEM_DATAGRID_STORAGE, "{}")
            }
            store.dispatch({
                type: "PUSH_HISTORY",
                history: location.pathname
            })
        }

    }, [location])

    return (
        <Router history={history}>
            <Route path="/" component={App} onEnter={onEnterApp(store)}>
                <Route path="login" component={Login} />
                <Route path="account/create" component={CreateAccount} />
                <Route path="account/request-password-change" component={RequestPasswordChange} />
                <Route path="account/change-password" component={ChangeAccountPassword} />

                {/* products */}
                <Route path="products" onEnter={onEnterProducts(store)} exact />
                <Route path="products" onEnter={onEnterProducts(store)}>
                    {/* recipe */}
                    <Route path="recipes" onEnter={onEnterRecipes(store)} component={RecipesTab} />
                    <Route path="recipe/:tab/:id" exact component={RecipeNew} onEnter={onEnterSingleRecipe(store)} />

                    {/* subcontractor-product */}
                    <Route path="subcontractors-products" component={SubcontractorProductsTab} onEnter={onEnterSubcontractorsProducts(store)} />
                    <Route path="subcontractor-product/:tab/:id" exact component={SubcontractorProductNew} onEnter={onEnterSingleSubcontractorProduct(store)} />

                    <Route path="advanced-search" component={AdvancedSearchContainer} onEnter={onEnterAdvancedSearch(store)} />
                </Route>

                {/* Ingredients */}
                <Route path="ingredients">
                    <Route onEnter={onEnterCommercialNames(store)}>
                        <Route path="internals" component={InternalCommercialNamesTab} />
                        <Route path="internal" exact component={CommercialNameForm} onEnter={onEnterSingleCommercialName(store)} />
                        <Route path="internal/:id" component={CommercialName} onEnter={onEnterSingleCommercialName(store)} />
                        <Route path="internal/:id/edit" components={CommercialNameForm} onEnter={onEnterSingleCommercialName(store)} />
                    </Route>
                    <Route path="externals" onEnter={onEnterSubcontractorsCommercialNames(store)} component={SubcontractorIngredientsTab} />
                    <Route path="external" onEnter={onEnterSingleSubcontractorCommercialName(store)} component={SubcontractorCommercialNameForm} exact />
                    <Route path="external/:id" onEnter={onEnterSingleSubcontractorCommercialName(store)} component={SubcontractorCommercialName} />
                    <Route path="external/:id/edit" onEnter={onEnterSingleSubcontractorCommercialName(store)} component={SubcontractorCommercialNameForm} />
                </Route>


                {/* Suppliers */}
                <Route path="suppliers">
                    <Route onEnter={onEnterSuppliers(store)}>
                        <Route path="list" component={SuppliersTab} />
                        <Route path="supplier/:tab/:id" exact component={Supplier} onEnter={onEnterSingleSupplier(store)} onLeave={onLeaveSingleSupplier(store)} />
                    </Route>
                    <Route path="supplierItems" components={SupplierItemsTab} onEnter={onEnterSupplierItems(store)} />
                    <Route path="supplierItems/:id" component={SupplierItem} onEnter={onEnterSingleSupplierItem(store)} />
                </Route>
                <Route>
                    <Route path="orders" component={Orders} onEnter={onEnterOrders(store)} />
                    <Route path="orders/:site" component={OrdersTabs} onEnter={onEnterOrdersList(store)} />
                    <Route path="order/:site" component={Order} onEnter={onEnterNewOrder(store)} />
                    <Route path="order/:site/:orderId" component={Order} onEnter={onEnterOrderUpdate(store)} />
                </Route>
                <Route>
                    <Route path="orderReceptions" exact component={OrderReception} onEnter={onEnterOrderReception(store)} />
                    <Route path="orderReceptions/:site/:date" component={OrderReceptionTabs} onEnter={onEnterOrderReceptionList(store)} />
                    <Route path="orderReceptions/:site/:date/:id" component={SingleOrderReception} onEnter={onEnterSingleOrderReception(store)} />
                    <Route path="orderReceptions/:site/:date/:id/:orderSupplierItemId/quantities" component={OrderReceptionQuantities} onEnter={onEnterOrderReceptionQuantities(store)} />
                    <Route path="orderReceptions/:site/:date/:id/deliveryNote" component={OrderDeliveryNote} onEnter={onEnterOrderDeliveryNote(store)} />
                    <Route path="orderReceptions/:site/:date/:id/deliveryNoteControl" component={DeliveryNoteMainControl} onEnter={onEnterOrderDeliveryNote(store)} />
                    <Route path="orderReceptions/:site/:date/:id/:orderSupplierItemId" component={SupplierItemLots} onEnter={onEnterOrderSupplierItemLots(store)} />
                </Route>
                <Route>
                    <Route path="lotInventory" component={LotInventory} onEnter={onEnterLotFilter(store)} />
                    <Route path="lotHistory" component={LotInventory} onEnter={onEnterLotFilter(store, true)} />
                    <Route path="lotOutput" component={LotOutput} onEnter={onEnterLotFilter(store)} />
                    <Route path="lotInventory/:stockZone" component={LotMain} onEnter={onEnterLotMain(store)} />
                    <Route path="lotDamageReport/:stockZone/:lotId" component={LotDamageReport} onEnter={onEnterLotDamageReport(store)} />
                    <Route path="lotOutput/:stockZone/:date" component={LotMain} onEnter={onEnterLotMain(store)} />
                    <Route path="lotOutput/:stockZone/:date/events" component={LotOutputEvents} onEnter={onEnterLotsEvents(store)} />
                    <Route path="lotInventory/lotEventsHistory/:lotId" component={LotEventsHistory} onEnter={onEnterLotEventsHistory(store)} />
                </Route>

                <Route path="secondaryDLC" component={SecondaryDLCFilter} onEnter={onEnterSecondaryDLCFilter(store)} />
                <Route path="secondaryDLC/:siteId/:kitchenAreaId" component={SecondaryDLC} onEnter={onEnterSecondaryDLC(store)} />

                {/* Productions steps execution */}
                <Route path="productionStepExecutions" component={ProductionStepsSupervision} onEnter={onEnterProductionStepsSupervision(store)} exact>
                    <Route path="filter" component={ProductionStepsFilter} />
                    <Route path="consultation" component={ProductionStepsRead} onEnter={onEnterProductionStepsRead(store)} />
                    <Route path="hours" component={ProductionStepsHoursEdit} onEnter={onEnterProductionStepsHoursEdit(store)} />
                </Route>
                <Route path="productionStepExecutions/type/unpacking" component={UnpackingPSEs} onEnter={onEnterUnpackingPSEsBySupplierItems(store)} />
                <Route path="productionStepExecutions/:id" component={ProductionStepExecution} onEnter={onEnterProductionStepExecution(store)} />

                {/* Production substitutions */}
                <Route path="productionSubstitutions" component={ProductionSubstitutions} onEnter={onEnterProductionSubstitutions(store)} />
                {/* Production Reception */}
                <Route path="productionReception" component={null} exact>
                    <Route path="filter" component={ProductionReceptionFilter} onEnter={onEnterProductionReceptionFilterExecution(store)} />
                    <Route path="productionSupplytoReceive/:productionSupplyId" component={ProductionReceptionToReceive} onEnter={onEnterProductionReceptionToReceiveExecution(store)} />
                    <Route path=":siteId/:stockZoneId/:date" component={ProductionReception} onEnter={onEnterProductionReceptionExecution(store)} />
                </Route>

                {/* Packaging execution */}
                <Route path="packagingExecutions" component={null} exact>
                    <Route path="filter" component={PackagingExecutionFilter} onEnter={onEnterPackagingExecutionFilter(store)} />
                    <Route path="read/:siteId/:date" component={PackagingExecutionRead} onEnter={onEnterPackagingExecutionRead(store)} />
                    <Route path="hours/:siteId/:date" component={PackagingExecutionHoursEdit} onEnter={onEnterPackagingExecutionHoursEdit(store)} />
                    <Route path="packagingExecution/:siteId/:date/:packagingExecutionId" component={PackagingExecution} onEnter={onEnterPackagingExecution(store)} />
                </Route>

                {/* Productions */}
                <Route onEnter={onEnterProductions(store)}>
                    <Route path="productions" component={ProductionList} />
                    <Route path="production" exact component={ProductionCreation} onEnter={onEnterSingleProduction(store)} />
                    <Route path="production/:id" component={ProductionDetail} onEnter={onEnterSingleProduction(store)} />
                </Route>

                {/* Dispatch */}
                <Route onEnter={onEnterDispatch(store)}>
                    <Route path="dispatch" component={Dispatch} onEnter={onEnterModalDispatch(store)} />
                    <Route path="dispatch/products/:siteId" component={ProductDispatch} onEnter={onEnterDispatch(store)} />
                    <Route path="dispatch/hubs/:siteId" component={HubDispatch} onEnter={onEnterDispatch(store)} />
                    <Route path="dispatch/:siteId/:date/:id" component={DispatchDetail} onEnter={onEnterDispatchDetail(store)} />
                    <Route path="dispatch/hubs/:siteId/:date/:id/:idHub" component={HubDispatchDetail} onEnter={onEnterDispatchDetail(store, false, true)} />
                </Route>

                {/* Hub reception */}
                <Route path="receptionEntrepotFilter" component={ReceptionWarehouseFilter} onEnter={onEnterReceptionWarehouseFilter(store)} />
                <Route onEnter={onEnterReceptionWarehouse(store)}>
                    <Route path="receptionEntrepot" component={ReceptionWarehouse} />
                    <Route path="receptionEntrepot/single/:date/:id" component={SingleReceptionWarehouseDetail} onEnter={onEnterSingleReceptionWarehouseDetail(store)} />
                    <Route path="receptionEntrepot/multi/:date/:packagingType" component={MultiReceptionWarehouseDetail} onEnter={onEnterMultipleReceptionWarehouseDetail(store)} />
                </Route>
                <Route>
                    <Route path="billingCreditNotes" exact component={BillingCreditNotes} onEnter={onEnterBillingAndCreditNotes(store)} />
                    <Route path="billingCreditNotes/sites/:siteIds" exact component={BillingCreditNotesTabs} onEnter={onEnterBillingAndCreditNotesList(store)} />
                    <Route path="billingCreditNotes/:site/:date/:id/control" exact component={BillingCreditNotesControl} onEnter={onEnterOrderDeliveryNote(store)} />
                </Route>

                {/* Warehouse lot Group */}
                <Route path="lotGroupFilter" component={LotGroupFilter} onEnter={onEnterLotGroupFilter(store)} />
                <Route onEnter={onEnterLotGroup(store)} >
                    <Route path="lotGroup" components={LotGroup} />
                    <Route path="lotGroup/:date/:id" component={LotGroupDetail} onEnter={onEnterLotGroupDetail(store)} />
                </Route>

                {/* Planning */}
                <Route path="planning" exact component={PlanningPage} />
                <Route path="planning">
                    <Route path="sell" onEnter={onEnterPlanningSell(store)} component={PlanningSell} />
                    <Route path="prev" onEnter={onEnterPlanningPrev(store)} component={PlanningPrev} />
                    <Route path="production" onEnter={onEnterPlanningProduction(store)} component={PlanningsProductionTab} />
                    <Route path="packaging" onEnter={onEnterPlanningPackaging(store)} component={PlanningsPackagingTab} />

                    <Route path="dashboard" onEnter={onEnterPlanningDashboard(store)}>
                        <Route path="monitoring" onEnter={onEnterPlanningDashboardMonitoring(store)} component={Monitoring} />
                        <Route path="ko/:brand" onEnter={onEnterPlanningDashboardKo(store)} component={KitchenOutputTab} />
                    </Route>

                    <Route path="dispatch" onEnter={onEnterDispatch(store)} component={Dispatch} />
                    <Route path="settings" exact onEnter={onEnterPlanningSetting(store)} component={SettingsTabs} />
                </Route>

                {/* New settings */}
                <Route path="settings">
                    {/* administration */}
                    <Route path="administration" component={AdministrationTabs} exact onEnter={onEnterSettingsAdministrationtTab(store)} />
                    <Route path="administration">
                        {/* productTypes */}
                        <Route path="productTypes" onEnter={onEnterProductTypes(store)} component={ProductTypesTab} exact />
                        <Route path="productTypes/edition" onEnter={onEnterProductTypes(store)} component={EditProductTypes} />

                        {/* packagingMaterials */}
                        <Route path="packagingMaterials" onEnter={onEnterPackagingMaterials(store)} component={PackagingMaterialsTab} exact />
                    </Route>

                    {/* ingredients */}
                    <Route path="ingredients" component={SettingsTabs} exact />
                    <Route path="ingredients">
                        <Route path="classifications" onEnter={onEnterClassificationsIngredients(store)} component={ClassificationsTab} exact />
                        <Route path="classifications" onEnter={onEnterClassificationsIngredients(store)}>
                            <Route onEnter={onEnterGroupsClassificationsIngredients(store)}>
                                <Route path="groups" component={GroupsIngredientsTab} />
                                <Route path="group/:id" component={GroupIngredientRead} />
                                <Route path="group" onEnter={onEnterSingleGroupClassificationIngredients(store)} component={GroupIngredientForm} exact />
                                <Route path="group/:id/edit" onEnter={onEnterSingleGroupClassificationIngredients(store)} component={GroupIngredientForm} />
                            </Route>

                            <Route onEnter={onEnterFamiliesClassificationsIngredients(store)}>
                                <Route path="families" component={FamiliesIngredientsTab} />
                                <Route path="family/:id" component={FamilyIngredientRead} />
                                <Route path="family" component={FamilyIngredientForm} exact />
                                <Route path="family/:id/edit" component={FamilyIngredientForm} />
                            </Route>

                            <Route onEnter={onEnterAllergensClassificationsIngredients(store)}>
                                <Route path="allergens" component={AllergensIngredientsTab} />
                                <Route path="allergen/:id" component={AllergenIngredientRead} />
                                <Route path="allergen" component={AllergenIngredientForm} exact />
                                <Route path="allergen/:id/edit" component={AllergenIngredientForm} />
                            </Route>
                        </Route>
                    </Route>

                    {/* technical sheet */}
                    <Route path="resources" component={TechnicalSheetTabs} onEnter={onEnterProductsResources(store)} exact />
                    <Route path="resources" onEnter={onEnterProductsResources(store)}>
                        <Route path="packagings" component={PackagingTab} onEnter={onEnterPackaging(store)} />
                        <Route path="packaging" exact component={PackagingForm} onEnter={onEnterSinglePackaging(store)} />
                        <Route path="packaging/:id" component={Packaging} onEnter={onEnterSinglePackaging(store)} />
                        <Route path="packaging/:id/edit" component={PackagingForm} onEnter={onEnterSinglePackaging(store)} />

                        <Route path="sections" component={SectionsGenericsTab} onEnter={onEnterSectionsGeneric(store)} />
                        <Route path="section" exact component={SectionGenericForm} onEnter={onEnterSingleSectionGeneric(store)} />
                        <Route path="section/:id" component={SectionGenericForm} onEnter={onEnterSingleSectionGeneric(store)} />

                        <Route path="reusableSteps" component={ReusableStepsTab} onEnter={onEnterReusableProductionSteps(store)} />
                        <Route path="reusableSteps">
                            <Route path="add" component={ReusableStepForm} onEnter={onEnterReusableProductionStepCreation(store)} />
                            <Route path="view/:id" component={ReusableStepForm} onEnter={onEnterReusableProductionStepView(store)} />
                            <Route path="edit/:id" component={ReusableStepForm} onEnter={onEnterReusableProductionStepEdition(store)} />
                        </Route>

                        <Route onEnter={onEnterProductsTags(store)}>
                            <Route path="tags" component={ProductsTagsResourcesTab} />
                            <Route path="tag/:id" component={ProductTagRead} />
                            <Route path="tag" exact component={ProductTagForm} />
                            <Route path="tag/:id/edit" component={ProductTagForm} />
                        </Route>

                        <Route onEnter={onEnterChefs(store)}>
                            <Route path="chefs" components={ChefsTabs} />
                            <Route path="chef/:id" components={ChefRead} />
                            <Route path="chef" components={ChefForm} />
                            <Route path="chef/:id/edit" components={ChefForm} />
                        </Route>
                    </Route>

                    {/* production */}
                    <Route path="production" component={ProductionTabs} exact />
                    <Route path="production">
                        <Route path="reports" onEnter={onEnterPlanningSettingReport(store)} component={ReportsTab} />
                        <Route path="productionSchemas" component={ProductionSchemasTab} exact onEnter={onEnterProductionSchemas(store)}>
                            <Route path="form" component={ProductionSchemasForm} />
                        </Route>
                        <Route path="machines" component={MachinesTab} onEnter={onEnterMachineTypes(store)} />
                        <Route path="transformationModes" component={TransformationModesTab} onEnter={onEnterTransformationModes(store)} />
                        <Route path="machines/:id/models" component={MachineModels} onEnter={onEnterSingleMachineType(store)} />
                    </Route>

                    {/* sites and hubs */}
                    <Route path="sites" component={SitesAndHubsTabs} exact />
                    <Route path="sites" onEnter={onEnterSites(store)}>
                        {/* sites */}
                        <Route path="sites" component={SitesTab} />
                        <Route path="site/kitchenArea/:id/machines" exact component={KitchenAreaMachines} onEnter={onEnterKitchenArea(store)} />
                        <Route path="site/:tab/:id" exact component={Site} onEnter={onEnterSingleSite(store)} />

                        {/* distributionCenters */}
                        <Route path="distributionCenters" exact>
                            <Route path="list" onEnter={onEnterPlanningSettingsDistributionCentersList(store)} component={DistributionCentersTab} />
                            <Route path="view/:id" onEnter={onEnterPlanningSettingSingleDistributionCenter(store)} component={DistributionCenterViewPage} />
                        </Route>

                        {/* dispatch priority */}
                        <Route path="dispatchPriority" onEnter={onEnterPlanningSettingsDispatchPriority(store)} component={SettingsDispatchPriorityTab} />
                    </Route>
                </Route>

                <Route path="receptionGap" onEnter={onEnterReceptionGap(store)} components={ReceptionGap} />

                <Route onEnter={onEnterMealPlanner(store)} path="mealplanner/:date/:brand" component={MealPlanner} />

                <Route onEnter={onEnterWeeklyMealPlanner(store)} path="weeklyMealplanner/:year/:week" component={WeeklyMealPlannerPage} />

                <Route onEnter={onEnterReceptionFilter(store)} >
                    <Route path="reception" components={Reception} />
                    <Route path="receptionFilter" components={ReceptionFilter} />
                </Route>

                <Route onEnter={onEnterInventoryFilter(store)} >
                    <Route path="inventory" components={Inventory} />
                    <Route path="inventoryFilter" components={InventoryFilter} />
                </Route>

                <Route path="rights" components={Rights} exact />
                <Route path="rights">
                    <Route path="roles" onEnter={onEnterRightsRoles(store)} component={RightsRolesTab} />
                    <Route path="users" onEnter={onEnterRightsUsers(store)} components={RightsUsersTab} />
                </Route>
                <Route path="misc" component={Misc} onEnter={onEnterMisc(store)} />
            </Route>
            <Route path="*" component={NotFound} />

        </Router>
    )
}
export default Routes
