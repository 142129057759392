import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Box, Tab, Tabs } from "@mui/material"
import UnpackingPSEsHeader from "./UnpackingPSEsHeader"
import UnpackingPSEsByIngredientsForm from "./UnpackingPSEsByIngredientsForm"
import { getIngredientsDoneSelector, getIngredientsTodoSelector } from "../../../reducers/Production/ProductionSteps"

const sx = {
  tabsContainer: {
    borderBottom: 1,
    borderColor: "divider",
    display: "flex",
    alignSelf: "stretch",
  },
  tabs: {
    flex: 1,
    display: "flex",
    alignSelf: "stretch",
  },
  tab: {
    textAlign: "center",
    minWidth: "50%"
  }
}

const UnpackingPSEs = () => {
  const ingredientsTodo = useSelector(getIngredientsTodoSelector)
  const ingredientsDone = useSelector(getIngredientsDoneSelector)

  const [tab, setTab] = useState(0)

  const handleChangeTab = (_, newValue) => {
    setTab(newValue)
  }
  
  const handleSelectIngredients = (ingredient, status) => {
    console.log("🚀 ~ TODO handleSelectIngredients ~ ingredient:", ingredient, status)
  }

  return (
    <>
      <UnpackingPSEsHeader />
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={sx.tabsContainer}>
          <Tabs
            value={tab}
            textColor="primary"
            indicatorColor="primary"
            onChange={handleChangeTab}
            sx={sx.tabs}
            aria-label="Unpacking Ingredients Tabs"
          >
            {[`Produits à DÉConditionner (${ingredientsTodo?.length})`, `PRODUITS DÉConditionnés (${ingredientsDone?.length})`].map((title, index) => (
              <Tab
                key={index}
                label={title}
                sx={sx.tab}
              />
            ))}
          </Tabs>
        </Box>
        {tab === 0 && (
          <UnpackingPSEsByIngredientsForm
            ingredients={ingredientsTodo}
            onSelect={handleSelectIngredients}
            status="TODO"
          />
        )}
        {tab === 1 && (
          <UnpackingPSEsByIngredientsForm
            ingredients={ingredientsDone}
            onSelect={handleSelectIngredients}
            status="DONE"
          />
        )}
      </Box>
    </>
  )
}

export default UnpackingPSEs