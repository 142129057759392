import React, {
    useEffect,
    useState,
    useMemo,
    useCallback
} from "react"
import { makeStyles } from "@mui/styles"
import {
    COLORS,
    setLotInventoryOutputStorage,
    setSearchValue
} from "../../utils"
import FullScreenWrapper from "../../components/FullScreenWrapper"
import { useSelector, useDispatch } from "react-redux"
import {
    showLotFilter,
    updateLotQuantity,
    closeInventoryOutputSnackBar,
    loadDisplayLotsFilterParams,
    openInventoryOutputSnackBar,
    addLotToInventory,
    showLotsEvents, updateHypotheticalLotAsTreated
} from "../../actions/LotInventory/LotInventory"
import Header from "../../components/LotInventory/Header"
import LeftSideWrapper from "../../components/Order/Lot/LeftSideWrapper"
import RightSideWrapper from "../../components/Order/Lot/RightSideWrapper"
import MainFilter from "../../components/LotInventory/MainFilter"
import {
    DLC_FILTER_TYPES,
    LOT_INVENTORY_MODE,
    filterLotsByGroupIngredient,
    filterLotsByDlc,
    filterLotsBySearchValue,
    getLotsGroupName,
    LOT_OUTPUT_MODE,
    filterQuantityOutput,
    filteredOutputLots,
    addHypotheticalOutputLots,
    filterOnRawMaterial, cleanHypotheticalLots
} from "../../utils/lotInventoryUtils"
import RoundedAddButton from "../../components/RoundedAddButton"
import ActionContent from "../../components/LotInventory/ActionContent"
import LotInventoryOutputSnackBar from "../../components/LotInventory/LotInventoryOutputSnackBar"
import cloneDeep from "lodash/cloneDeep"
import moment from "moment"
import { getLot, getLotsBySupplierItemsAndStockZones, createLotEditionEvent, addLotEvent } from "../../parseManager/lot/parseLotManager"
import { getStockZone } from "../../parseManager/site/parseSiteManager"
import { getDisplayLotsBySuppliers } from "../../reducers/LotInventory/LotInventory"
import DisplayLotsToInventoryModal from "./DisplayLotsToInventoryModal"
import { Button, CircularProgress } from "@mui/material"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"
import LotHypotheticalActionContent from "../../components/LotInventory/LotHypotheticalActionContent"
import { getSupplierItemWithId } from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"
import { getAvailableLotsForSubstitutes, updateHypotheticalLotOnSubstituteChange } from "../../utils/lotsUtils"
import GroupIngredientsSection from "../../components/common/GroupIngredientsSection"

const useStyles = makeStyles(() => ({
    root: {
        height: "100%",
    },
    tabsContainer: {
        marginTop: 72,
        display: "flex",
        flexDirection: "row",
        borderBottom: `1px solid ${COLORS.LIGHT_GREY_3}`
    },
    lastTab: {
        marginLeft: "auto"
    },
    floatingButton: {
        position: "absolute",
        bottom: 20,
        left: 20
    },
    noAdd: {
        textAlign: "center",
        maxWidth: 438,
        fontWeight: 300,
        fontSize: 37,
        lineHeight: "43px",
        marginTop: 120
    },
    rightSide: {
        display: "flex",
        maxWidth: 350,
        width: "350px",
    },
    actionRoot: {
        textAlign: "center",
        alignSelf: "center",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1
    },
    filter: {
        position: "relative",
        width: "100%",
        marginTop: 64
    },
    cardsContainer: {
        width: "100%",
    },
    cardWrapper: {
        marginRight: 13,
        cursor: "pointer",
        ".selected": {
            background: COLORS.PRIMARY_COLOR,
            color: COLORS.WHITE
        }
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "stretch",
        flexBasis: "100%"
    }
}))

const LotMain = () => {
    const site = useSelector(state => state.lotInventory.site)
    const warningDate = useSelector(state => state.lotInventory.warningDate)
    const stockZone = useSelector(state => state.lotInventory.stockZone)
    const mode = useSelector(state => state.lotInventory.mode)
    const lotsData = useSelector(state => state.lotInventory.lots)
    const outputInventoryData = useSelector(state => state.lotInventory.outputInventoryData)
    const date = useSelector(state => state.lotInventory.date)
    const inventoryOutputSnackBar = useSelector(state => state.lotInventory.inventoryOutputSnackBar)
    const allSubstituteLots = useSelector(state => state.lotInventory.substituteLots)
    const supplierItemsHypotheticalTreated = useSelector(state => state.lotInventory.supplierItemsHypotheticalTreated)
    const productTypesOptions = useSelector(getProductTypeOptionsSelector)
    const suppliers = useSelector(getDisplayLotsBySuppliers)
    const isInventory = mode === LOT_INVENTORY_MODE
    const groupIngredients = useSelector(state => state.lotInventory.groupIngredients)
    const dlcFilterTypes = isInventory ? DLC_FILTER_TYPES : DLC_FILTER_TYPES.filter(el => el.key !== "PASSED")
    const [searchValue, updateSearchValue] = useState("")
    const [selectedDlcTypes, setSelectedDlcTypes] = useState(["ALL", ...dlcFilterTypes.map(el => el.key)])
    const [selectedGroupIngredients, setSelectedGroupIngredients] = useState([])
    const [focusSearch, setFocusSearch] = useState(true)
    const [selectedLot, setSelectedLot] = useState(null)
    const [lastSelectedLot, setLastSelectedLot] = useState(null)
    const [inputValue, setInputValue] = useState("")
    const [showEditLot, setShowEditLot] = useState(false)
    const [openDisplayLotsModal, setOpenDisplayLotsModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedOutputLots, setSelectedOutputLots] = useState("SELECTED_LOTS")
    const substituteIds = useSelector(state => state.lotInventory.substituteIds)
    const [shouldScrollToLastLotUsed, setShouldScrollToLastLotUsed] = useState(false)
    const [substituteLots, setSubstituteLots] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const classes = useStyles()
    const dispatch = useDispatch()

    const scrollIntoLastLot = () => {
        setTimeout(() => {
            document.getElementById(`lot-${lastSelectedLot.objectId}`)?.parentElement?.parentElement?.scrollIntoView({
                behavior: "instant",
                block: "center"
            })
        }, 50)

        setShouldScrollToLastLotUsed(false)
    }

    const lots = useMemo(() => {
        let lots = cloneDeep(lotsData)

        if (!lots) return []

        if (lots.size === 0) {
            return lots
        }

        if (typeof lots !== "undefined") {
            lots = filterLotsByGroupIngredient(lots, selectedGroupIngredients)
            lots = filterLotsByDlc(lots, selectedDlcTypes, date, warningDate, isInventory)
            lots = filterQuantityOutput(lots, outputInventoryData, date, mode)

            if (mode === LOT_OUTPUT_MODE) {
                lots = addHypotheticalOutputLots(lots, outputInventoryData, stockZone, allSubstituteLots, date, supplierItemsHypotheticalTreated)
                lots = filterOnRawMaterial(lots)
                if (selectedOutputLots === "SELECTED_LOTS") {
                    lots = filteredOutputLots(lots, outputInventoryData, date)
                }
            }

            lots = filterLotsBySearchValue(lots, searchValue)
        } else {
            lots = []
        }

        return lots
    }, [lotsData, selectedDlcTypes, selectedGroupIngredients, date, warningDate, searchValue, isInventory, selectedOutputLots])

    const onSearchChange = (name) => {
        updateSearchValue(name)
        setSearchValue(name, mode)
    }

    // back to filter if no hub selected
    useEffect(() => {
        if (!site && !stockZone) {
            goBack()
        }

        const filterStorage = JSON.parse(localStorage.getItem("filterLotInventoryOutput"))

        if (filterStorage && filterStorage[mode]) {
            if (filterStorage[mode].dlc) setSelectedDlcTypes(filterStorage[mode].dlc)
            if (filterStorage[mode].groupIngredients) setSelectedGroupIngredients(filterStorage[mode].groupIngredients)
        }

        const storage = JSON.parse(localStorage.getItem("searchValue"))
        if (storage && storage[mode]) {
            updateSearchValue(storage[mode])
        }

        setFocusSearch(true)
        updateSearchValue("")
    }, [])

    const goBack = () => dispatch(showLotFilter(mode))

    const inputActionChange = (value) => {
        if (isInventory) {
            if ((typeof +value === "number" && value >= 0) || value === "") {
                setInputValue(value)
            }
            return
        }
        // can make a negative output
        if ((typeof +value === "number" && value <= selectedLot.quantity) || value === "") {
            setInputValue(value)
        }
    }

    const handleValidate = useCallback(async () => {
        setLastSelectedLot(selectedLot)
        const quantityValue = !inputValue || inputValue === "" ? selectedLot.quantity : inputValue
        await dispatch(updateLotQuantity(selectedLot, quantityValue, mode, date))

        if (selectedLot.isSubstitute) {
            updateHypotheticalLotOnSubstituteChange(selectedLot, lots, quantityValue)
            await updateSubstituteLotsAvailable(selectedLot.originalLot)
            setSelectedLot(selectedLot.originalLot)

        } else {
            setSelectedLot(null)
        }

        setInputValue("")
        setShouldScrollToLastLotUsed(true)
    }, [selectedLot, inputValue, date])


    const handleEdit = (e) => {
        e.preventDefault()
        setShowEditLot(true)
    }

    const editLot = async (lotUpdated) => {
        const parseLot = await getLot({ id: lotUpdated.id, toJson: false})
        const stockZone = await getStockZone({ stockZoneId: lotUpdated.stockZone.objectId, toJson: false })
        const initialStockZone = await getStockZone({ stockZoneId: selectedLot.stockZone.objectId, toJson: false })
        
        // Create and save in the dB a new event for each DLC / lotNumber/ stockZone modified
        const newEvents = await createLotEditionEvent(lotUpdated.dlc, selectedLot.dlc, lotUpdated.lotNumber, selectedLot.lotNumber, stockZone, initialStockZone)
        newEvents.forEach(event => addLotEvent(parseLot.id, event))
        // Add them to the client-side lotUpdated
        lotUpdated.events = [...(lotUpdated.events || []), ...newEvents]
        parseLot.set("dlc", lotUpdated.dlc)
        parseLot.set("lotNumber", lotUpdated.lotNumber)
        parseLot.set("stockZone", stockZone)
        await parseLot.save()

        triggerUpdatedLot(lotUpdated)
        disableSelection()
    }

    const closeHypotheticalLot = async (lot) => {
        await dispatch(updateHypotheticalLotAsTreated(lot, date))
        /** remove hypothetical lot closed from allLots displayed **/
        cleanHypotheticalLots(lots, lot)
        disableSelection()
    }
    const triggerUpdatedLot = (lotUpdated) => {
        for (const [, value] of lots) {
            for (const [, values] of value) {
                values.forEach(elm => {
                    if (elm.objectId === lotUpdated.id) {
                        elm.stockZone = lotUpdated.stockZone
                        elm.quantity = lotUpdated.quantity
                        elm.lotNumber = lotUpdated.lotNumber
                        elm.receptionDate = lotUpdated.receptionDate
                        elm.events = lotUpdated.events
                        elm.dlc = lotUpdated.dlc
                    }
                })
            }
        }
    }
    const disableSelection = () => {
        setShowEditLot(null)
        setInputValue("")
        setShouldScrollToLastLotUsed(true)
        if (selectedLot && selectedLot.isSubstitute) {
            setSelectedLot(selectedLot.originalLot)
        } else {
            setSelectedLot(null)
        }
    }

    const getAction = () => {
        return (
            <div className={classes.actionRoot}>
                {
                    selectedLot ?
                        selectedLot.hypothetical && mode === LOT_OUTPUT_MODE ?
                            <LotHypotheticalActionContent
                                lot={selectedLot}
                                isAllZones={stockZone === null}
                                substituteLots={substituteLots}
                                onSelectSubstitute={onSelectLot}
                                closeHypotheticalLot={closeHypotheticalLot}
                                disableSelection={disableSelection}
                            /> : <ActionContent
                                mode={mode}
                                lot={selectedLot}
                                inputValue={inputValue}
                                stockZone={stockZone}
                                setValue={inputActionChange}
                                handleValidate={handleValidate}
                                handleEdit={handleEdit}
                                disableSelection={disableSelection}
                                showEditLot={showEditLot}
                                editLot={editLot}
                                site={site}
                                isAllZones={stockZone === null}
                            />
                        :
                        null
                }
                {!selectedLot &&
                    isLoading ? <div className={classes.loader}><CircularProgress /></div> :
                    !selectedLot &&
                    <div className={classes.noAdd}>
                        Choisissez un Produit
                    </div>
                }
            </div>
        )
    }

    const onDlcTypeChange = (value) => {
        if (selectedDlcTypes.includes("ALL") && value.includes("ALL") && selectedDlcTypes.length !== value.length) {
            value.shift()
        }
        else if (selectedDlcTypes.includes("ALL") && !value.includes("ALL")) {
            value = []
        }
        else if (
            (!value.includes("ALL") && value.length === dlcFilterTypes.length) ||
            (!selectedDlcTypes.includes("ALL") && value.includes("ALL"))
        ) {
            value = ["ALL", ...dlcFilterTypes.map(el => el.key)]
        }

        setSelectedDlcTypes(value)
        setLotInventoryOutputStorage(value, "dlc", mode)
    }

    const onGroupIngredientChange = (value) => {
        setSelectedGroupIngredients(value)
        setLotInventoryOutputStorage(value, "groupIngredients", mode)
    }

    const onSelectLot = async (lot) => {
        if (lot.hypothetical) {
            setIsLoading(true)
            await updateSubstituteLotsAvailable(lot)
            setIsLoading(false)
        }

        disableSelection()
        setSelectedLot(lot)
        setLastSelectedLot(lot)
    }

    const updateSubstituteLotsAvailable = async (lot) => {
        const supplierItem = await getSupplierItemWithId(lot.orderSupplierItem.supplierItemId)
        const substituteSupplierItemIds = supplierItem.substitutionList && supplierItem.substitutionList.map(sub => sub.objectId)
        const stockZoneParam = lot.stockZone ? [lot.stockZone] : []
        const rawlots = await getLotsBySupplierItemsAndStockZones(substituteSupplierItemIds, stockZoneParam, false)

        setSubstituteLots(getAvailableLotsForSubstitutes(rawlots, supplierItem, lot, date, substituteSupplierItemIds))
    }
    const handleOpenDisplayLotsModal = async () => {
        setOpenDisplayLotsModal(true)
        setLoading(true)
        await dispatch(loadDisplayLotsFilterParams())
        setLoading(false)
    }

    const handleConfirmDisplayLots = async (lot) => {
        if (lot.data.quantity !== 0) {
            dispatch(openInventoryOutputSnackBar())
            return
        }

        // display the selected lot even if quantity is 0
        await dispatch(addLotToInventory(lot.data))
        onSelectLot(lot.data)
    }

    const goToLotsEvents = () => dispatch(showLotsEvents(stockZone.objectId, date))

    const headerRightButton = (
        <Button variant="contained" sx={{ px: 4 }} onClick={goToLotsEvents}>
            Voir les sorties réalisées
        </Button>
    )

    if (shouldScrollToLastLotUsed) {
        scrollIntoLastLot()
    }
    return (
        <FullScreenWrapper>
            <Header
                onClickReturn={goBack}
                site={site}
                stockZone={stockZone}
                date={moment(date).startOf("day").format("DD/MM/YYYY")}
                isInventory={isInventory}
                rightAction={mode === LOT_OUTPUT_MODE ? headerRightButton : undefined}
            />
            <div className={classes.root}>
                <LeftSideWrapper
                    fullWidth={false}
                >
                    <div className={classes.filter}>
                        <MainFilter
                            date={date}
                            onDlcTypeChange={onDlcTypeChange}
                            onGroupIngredientChange={onGroupIngredientChange}
                            onSearchChange={onSearchChange}
                            onOutputLotsSelection={setSelectedOutputLots}
                            isInventory={isInventory}
                            groupIngredients={groupIngredients}
                            dlcFilterTypes={dlcFilterTypes}
                            searchValue={searchValue}
                            selectedGroupIngredients={selectedGroupIngredients}
                            selectedDlcTypes={selectedDlcTypes}
                            selectedOutputLots={selectedOutputLots}
                            focusSearch={focusSearch}
                        />
                    </div>
                    <div className={classes.cardsContainer}>
                        {
                            Array.from(lots).map(([key, value]) =>
                                <GroupIngredientsSection
                                    key={key}
                                    mode={mode}
                                    group={getLotsGroupName(key, groupIngredients, productTypesOptions)}
                                    characters={value}
                                    currentDate={date}
                                    dlcWarningDate={warningDate}
                                    onSelect={onSelectLot}
                                    selectedItem={selectedLot}
                                    substituteIds={substituteIds}
                                    collection="Lot"
                                />
                            )
                        }
                    </div>
                </LeftSideWrapper>
                <RightSideWrapper
                    className={classes.rightSide}
                >
                    {getAction()}
                </RightSideWrapper>
            </div>
            <div>
                <RoundedAddButton
                    onClick={handleOpenDisplayLotsModal}
                />
            </div>

            {/* Notification snackbar */}
            <LotInventoryOutputSnackBar
                data={inventoryOutputSnackBar}
                onClose={() => dispatch(closeInventoryOutputSnackBar(inventoryOutputSnackBar.type))}
            />

            <DisplayLotsToInventoryModal
                open={openDisplayLotsModal}
                onClose={() => setOpenDisplayLotsModal(false)}
                suppliers={suppliers}
                stockZone={stockZone}
                loading={loading}
                onConfirm={handleConfirmDisplayLots}
            />
        </FullScreenWrapper>
    )
}

export default LotMain