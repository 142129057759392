import React, { useState, useEffect, useCallback } from "react"
import DataGrid from "../../components/DataGrid/DataGrid"
import { Box } from "@mui/material"
import dayjs from "dayjs"
import {
	LOT_INVENTORY_MODE,
	LOT_OUTPUT_MODE,
	LOT_RECEPTION_MODE,
	LOT_DAMAGE_MODE,
	LOT_DISPATCH_MODE,
	LOT_RETURN_MODE,
	getEventDescription,
	computeInitialQuantityFromEvents
	} from "../../utils/lotInventoryUtils"
import {capitalizeFirstLetter} from "../../utils"
import { cloneDeep } from "lodash"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(() => ({
	unitInformation: {
		marginLeft: -4
	}
}))

const _computeRemainingQuantity = (lot, event) => {
	if ([LOT_INVENTORY_MODE, LOT_RECEPTION_MODE].includes(event.mode)) {
		return event.quantity
	}

	// Get or set the initial quantity
	let quantityBeforeEvent = lot.initialQuantity || computeInitialQuantityFromEvents(lot)

	const previousEvents = lot.events.filter(e => e.date < event.date)
	previousEvents.forEach((e) => {
		if (e.mode === LOT_OUTPUT_MODE || e.mode === LOT_DAMAGE_MODE || e.mode === LOT_DISPATCH_MODE) {
			quantityBeforeEvent -= e.quantity
		}
		if (e.mode === LOT_RECEPTION_MODE || e.mode === LOT_INVENTORY_MODE) {
			quantityBeforeEvent = e.quantity
		}
		if (e.mode === LOT_RETURN_MODE) {
			quantityBeforeEvent += e.quantity
		}
	})

	return event.mode === LOT_RETURN_MODE ? quantityBeforeEvent + event.quantity : quantityBeforeEvent - event.quantity
}

const createData = async (event, lot) => {
	return {
		eventDate: dayjs.tz(event.date).format("DD/MM/YYYY HH:mm"),
		eventDescription: await getEventDescription(event, _computeRemainingQuantity(lot, event)),
		user: event.user?.username ? event.user.username.substring(0, event.user.username.indexOf("@")) : "Inconnu"
	}
}

const columnHeaders = [
  {
    key: "eventDate",
    label: "Date du mouvement",
    filterable: false,
    width: "calc((100% - 112px) * 0.125)" // 12.5% of the remaining width
  },
  {
    key: "eventDescription",
    label: "Description de l'événement",
    filterable: false,
    width: "calc((100% - 112px) * 0.75)" // 75% of the remaining width
  },
  {
    key: "user",
    label: "Utilisateur",
    filterable: false,
    width: "calc((100% - 112px) * 0.125)" // 12.5% of the remaining width
  }
]

const LotEventsTable = ({ lot }) => {

	const [data, setData] = useState([])
	const classes = useStyles()

	// most recent on top
	const sortEvents = (events) => {
		return events.sort((a, b) => b.date - a.date)
	}

	useEffect(() => {
		const fetchData = async () => {
			const events = lot.events || []
			const clonedEvents = cloneDeep(events)
			const sortedEvents = sortEvents(clonedEvents)

			// Remove DISPATCH events from the list
			const eventsToDisplay = sortedEvents.filter(event => {
				return ![LOT_DISPATCH_MODE].includes(event.mode)
			})
	
			const data = await Promise.all(eventsToDisplay.map((event) => createData(event, lot)))
			setData(data)
		}
	
		fetchData()
	}, [lot])

	const renderRecapRow = useCallback(() => {
		const stockUnits = lot.orderSupplierItem?.units?.stock || {}
		let unitsInformation = ""
		if (stockUnits.unity.name) {
			unitsInformation = `(${capitalizeFirstLetter(stockUnits.unity.name)} ${stockUnits.unity.quantity}`
			unitsInformation += stockUnits.unity.quantity > 1 ? " pièces)" : " pièce)"
		}
		else if (stockUnits.unity.quantity) {
			unitsInformation = `(${stockUnits.unity.quantity}`
			unitsInformation += stockUnits.unity.quantity > 1 ? " pièces)" : " pièce)"
		}
		return (
			<Box sx={{
				display: "flex",
				padding: "16px",
				backgroundColor: "#EBF7EE",
				color: "#262626",
				alignItems: "center",
				gap: "8px",
				alignSelf: "stretch",
				fontSize: "16px",
				lineHeight: "22px",
				"& :first-child": {
					fontSize: 14
				}
			}}>
				<span>Quantité restante:</span>
				<strong>
					{lot.quantity}
				</strong>
				<span className={classes.unitInformation}>{unitsInformation}</span>
			</Box>)
	}, [lot])

	return (
		<DataGrid
			withFilters={false}
			title="Mouvements de stock"
			data={data}
			columns={columnHeaders}
			renderRecapRow={renderRecapRow}
		/>
	)

}

export default LotEventsTable